$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.container {
  padding: 0 var(--b-space-l) var(--b-space-xl) var(--b-space-l);

  @media #{$fromLaptop} {
    padding: 0 var(--b-space-3xl) var(--b-space-xl) var(--b-space-3xl);
  }
}

.buttonWrapper {
  display: flex;
  gap: var(--b-space-m);
  flex-direction: column-reverse;
  justify-content: center;

  @media #{$fromLaptop} {
    flex-direction: row;
    justify-content: flex-end;
  }
}
