$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.container {
  position: fixed;
  z-index: var(--b-z-index-5);
  right: 0;
  bottom: var(--b-pageWidgets-bottomMargin, 0);
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  margin: var(--b-space-m);
  max-height: calc(
    var(--b-pageWidgets-containerHeight-current, 0) + var(--b-space-2xl) * 2
  );

  transition: max-height 0.3s ease;
  --b-pageWidgets-gap: var(--b-space-l);

  @media #{$fromLaptop} {
    --b-pageWidgets-gap: var(--b-space-2xl);

    margin: var(--b-space-xl);
  }
}

.item {
  transition: all 0.3s;

  &:not(.itemHidden) {
    & ~ & {
      margin-top: var(--b-pageWidgets-gap);

      @media #{$fromLaptop} {
        margin-top: var(--b-space-2xl);
      }
    }
  }
}

.itemHidden {
  opacity: 0;
  transform: scale(0);

  height: 0 !important;
}
