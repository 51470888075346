$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.button {
  --b-goToTopButton-progress-bgColor: var(--b-color-bg-light);
  --b-goToTopButton-progress-angle: calc(
    var(--b-goToTopButton-scrollPercent, 0) / 100 * 300deg
  );
  position: relative;

  display: grid;
  place-items: center;

  box-shadow: 0 toRem(4) toRem(8) 0px rgba(0, 0, 0, 0.1);
  border: toRem(2) solid var(--b-color-bg-white);
  border-radius: var(--b-border-radius-50-percent);
  width: var(--b-space-3xl);
  height: var(--b-space-3xl);

  background: rgba(255, 255, 255, 0.8);
  color: var(--b-color-button-secondary-enabled);
  cursor: pointer;

  &:hover {
    background: var(--b-color-button-secondary-hover);
    --b-goToTopButton-progress-bgColor: var(--b-color-button-secondary-hover);
  }

  &:focus {
    box-shadow: 0 0 toRem(2) toRem(1) rgba(26, 188, 254, 0.75);
  }

  &:active {
    background-color: var(--b-color-button-secondary-enabled);
    color: var(--b-color-button-inverse-enabled);
  }

  &::before {
    content: '';
    position: absolute;

    border: toRem(2) solid transparent;
    border-radius: inherit;

    inset: toRem(-1);

    background-image: conic-gradient(
      var(--b-color-fill-ocean-100) var(--b-goToTopButton-progress-angle),
      var(--b-goToTopButton-progress-bgColor)
        var(--b-goToTopButton-progress-angle)
    );
    background-origin: border-box;
    mask:
      linear-gradient(#000 0 0) border-box,
      linear-gradient(#000 0 0) padding-box;
    mask-composite: exclude;
  }
}
