$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';
@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/functions';

.notificationWrapper {
  position: fixed;
  z-index: var(--b-z-index-5);
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;

  padding-bottom: var(--b-space-m);

  animation: slideInBottom 0.5s ease-in-out;

  @media #{$fromTablet} {
    padding-bottom: var(--b-space-3xl);
  }
}

@keyframes slideInBottom {
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0);
  }
}

.notification {
  position: relative;

  border: toRem(1) solid var(--b-color-fill-neutral-03);
  border-radius: toRem(8);
  width: 100%;
  max-width: toRem(424);
  padding: var(--b-space-s) var(--b-space-2xl) var(--b-space-s) var(--b-space-s);

  color: var(--b-color-text-dark);
  background-color: var(--b-color-fill-neutral-01);

  &.success {
    border-color: var(--b-color-support-success-dark);

    background-color: var(--b-color-support-success-light);
  }

  &.info {
    border-color: var(--b-color-support-info-dark);

    background-color: var(--b-color-support-info-light);
  }

  &.warning {
    border-color: var(--b-color-support-warning-dark);

    background-color: var(--b-color-support-warning-light);
  }

  &.error {
    border-color: var(--b-color-support-error-dark);

    background-color: var(--b-color-support-error-light);
  }

  &.darkVariant {
    border-color: var(--b-color-fill-neutral-04);

    color: var(--b-color-text-light);

    background-color: var(--b-color-fill-neutral-04);
  }
}

.exitButton {
  position: absolute;
  right: var(--b-space-s);

  cursor: pointer;
}
