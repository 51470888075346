$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';
@import 'src/ui/styles/tools/mixins/common';

.modalContent {
  margin: var(--b-space-l) var(--b-space-l) var(--b-space-xl) var(--b-space-l);
  padding-right: var(--b-space-m);

  [dir='rtl'] & {
    padding-left: var(--b-space-m);
    padding-right: unset;
  }

  overflow-y: auto;
  overflow-x: hidden;

  @include personalizedModalWebkitScrollBar;

  @media #{$fromLaptop} {
    margin-top: var(--b-space-xl);
    margin-left: var(--b-space-3xl);
    margin-right: var(--b-space-xl);

    [dir='rtl'] & {
      margin-left: var(--b-space-xl);
      margin-right: var(--b-space-3xl);
    }
  }

  @media #{$onlyMobile} {
    &_isMobileFullscreen {
      max-height: 100%;
    }
  }
}
