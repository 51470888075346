$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.button {
  @include font-button;

  position: relative;

  border-radius: toRem(100);
  max-height: toRem(56);
  min-height: toRem(48);

  line-height: toRem(20);

  text-align: center;
}

.primary {
  border: unset;

  background: var(--b-color-button-primary-enabled);

  color: var(--b-color-text-light);

  &:hover:enabled {
    background: var(--b-color-button-primary-hover);
    cursor: pointer;
  }

  &:active:enabled {
    background: var(--b-color-button-primary-pressed);
  }
}

.secondary {
  box-shadow: 0 0 0 toRem(2) var(--b-color-button-secondary-enabled) inset;

  background: var(--b-color-bg-white);

  color: var(--b-color-button-secondary-enabled);

  &:hover {
    box-shadow: 0 0 0 toRem(2) var(--b-color-button-secondary-hover) inset;

    background: var(--b-color-button-secondary-hover);
    cursor: pointer;
  }

  &:active:enabled {
    box-shadow: 0 0 0 toRem(2) var(--b-color-button-secondary-pressed) inset;

    background: var(--b-color-button-secondary-pressed);
    color: var(--b-color-text-light);
  }
}

.primary__disabled {
  box-shadow: 0 0 0 toRem(3) var(--b-color-button-secondary-disabled) inset;

  pointer-events: none;

  background: var(--b-color-button-secondary-disabled);

  color: var(--b-color-interactive-secondary-disabled);
}

.myBarcelo-primary {
  border: unset;

  background: var(--b-color-button-myBarcelo-primary-enabled);

  color: var(--b-color-text-light);

  &:hover:enabled {
    background: var(--b-color-button-myBarcelo-primary-hover);
    color: var(--b-color-button-myBarcelo-primary-enabled);

    cursor: pointer;
  }

  &:active:enabled {
    background: var(--b-color-button-myBarcelo-primary-pressed);
    color: var(--b-color-text-light);
  }
}

.myBarcelo-secondary {
  box-shadow: 0 0 0 toRem(2) var(--b-color-button-myBarcelo-secondary-enabled)
    inset;

  background: var(--b-color-bg-white);

  color: var(--b-color-button-myBarcelo-secondary-enabled);

  &:hover {
    box-shadow: 0 0 0 toRem(2) var(--b-color-button-myBarcelo-secondary-hover)
      inset;

    background: var(--b-color-button-myBarcelo-secondary-hover);
    cursor: pointer;
  }

  &:active:enabled {
    box-shadow: 0 0 0 toRem(2) var(--b-color-button-myBarcelo-secondary-pressed)
      inset;

    background: var(--b-color-button-myBarcelo-secondary-pressed);
    color: var(--b-color-text-light);
  }
}

.secondary__disabled,
.myBarcelo-secondary__disabled {
  box-shadow: 0 0 0 toRem(3) var(--b-color-button-secondary-disabled) inset;

  pointer-events: none;

  background-color: var(--b-color-button-secondary-disabled);

  color: var(--b-color-interactive-secondary-disabled);
}

.on-image {
  border: toRem(2) solid var(--b-color-bg-white);

  background: var(--b-color-bg-white);

  color: var(--b-color-text-primary);

  &:enabled:hover {
    border-color: var(--b-color-button-on-image-hover);

    background: var(--b-color-button-on-image-hover);
    cursor: pointer;
  }
}

.cancel {
  box-shadow: 0 0 0 toRem(2) var(--b-color-button-cancel-enabled) inset;

  background: var(--b-color-bg-white);

  color: var(--b-color-button-cancel-enabled);

  &:hover {
    box-shadow: 0 0 0 toRem(2) var(--b-color-button-cancel-hover) inset;

    background: var(--b-color-button-cancel-hover);
    cursor: pointer;
  }

  &:active:enabled {
    box-shadow: 0 0 0 toRem(2) var(--b-color-button-cancel-pressed) inset;

    background: var(--b-color-button-cancel-pressed);
    color: var(--b-color-text-light);
  }
}

@media #{$onlyMobile} {
  .mobile-small {
    min-height: toRem(40);
    padding: var(--b-space-xxs) var(--b-space-m);
  }

  .mobile-large {
    padding: var(--b-space-xs) var(--b-space-xl);
  }

  .mobile-fullWidth {
    width: 100%;
    padding: var(--b-space-xs) var(--b-space-m);
  }

  .mobile-smallFullWidth {
    width: 100%;
    min-height: toRem(40);
    padding: var(--b-space-xxs) var(--b-space-m);
  }
}

@media #{$onlyTablet} {
  .tablet-small {
    min-height: toRem(40);
    padding: var(--b-space-xxs) var(--b-space-m);
  }

  .tablet-large {
    padding: var(--b-space-xs) var(--b-space-xl);
  }

  .tablet-fullWidth {
    width: 100%;
    padding: var(--b-space-xs) var(--b-space-m);
  }

  .tablet-smallFullWidth {
    width: 100%;
    min-height: toRem(40);
    padding: var(--b-space-xxs) var(--b-space-m);
  }
}

@media #{$onlyLaptop} {
  .laptop-small {
    min-height: toRem(40);
    padding: var(--b-space-xxs) var(--b-space-m);
  }

  .laptop-large {
    padding: var(--b-space-xs) var(--b-space-xl);
  }

  .laptop-fullWidth {
    width: 100%;
    padding: var(--b-space-xs) var(--b-space-m);
  }

  .laptop-smallFullWidth {
    width: 100%;
    min-height: toRem(40);
    padding: var(--b-space-xxs) var(--b-space-m);
  }
}

@media #{$onlyDesktop} {
  .desktop-small {
    min-height: toRem(40);
    padding: var(--b-space-xxs) var(--b-space-m);
  }

  .desktop-large {
    padding: var(--b-space-xs) var(--b-space-xl);
  }

  .desktop-fullWidth {
    width: 100%;
    padding: var(--b-space-xs) var(--b-space-m);
  }

  .desktop-smallFullWidth {
    width: 100%;
    min-height: toRem(40);
    padding: var(--b-space-xxs) var(--b-space-m);
  }
}

.contentLoading {
  visibility: hidden;
}

.loaderWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.fit-content {
  width: fit-content;
}

.max-content {
  width: max-content;
}

.transparent {
  background: transparent;
}
