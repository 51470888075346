$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.schedule {
  gap: var(--b-space-m);
  flex-direction: column;
}

.scheduleOptions {
  gap: var(--b-space-m);
  flex-direction: column;

  @media #{$fromLaptop} {
    gap: var(--b-space-l);
    flex-direction: row;
  }
}
