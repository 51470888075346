$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.container {
  position: relative;

  display: flex;
  justify-content: flex-end;
  align-items:  center;

  &:hover {
    .imageContainer {
      background-size: 110%;

      &::before {
        opacity: 0;
      }
    }

    .messageContainer {
      opacity: 1;
      transform: translateX(0);

      visibility: visible;
    }
  }
}

.clickableZone {
  position: relative;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  height: toRem(86);
}

.imageContainer {
  position: relative;
  z-index: var(--b-z-index-1);

  align-self: start;
  overflow: hidden;

  box-shadow: 0 toRem(2) toRem(12) 0 rgba(0, 0, 0, 0.3);
  border: toRem(2) solid var(--b-color-bg-white);
  border-radius: var(--b-border-radius-50-percent);
  width: toRem(80);
  height: toRem(80);

  background-image: url('https://s7g10.scene7.com/is/image/barcelo/widgetCMB?fmt=png8-alpha');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;

  cursor: pointer;

  transition:
    background-size 0.3s ease,
    box-shadow 0.3s ease;

  &::before {
    content: '';
    position: absolute;
    z-index: var(--b-z-index-1);
    top: 0;
    left: 0;

    border-radius: var(--b-border-radius-50-percent);
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s ease;
  }
}

.iconContainer {
  position: absolute;
  z-index: var(--b-z-index-2);

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 toRem(2) toRem(12) 0 rgba(0, 0, 0, 0.3);
  border-radius: var(--b-border-radius-50-percent);
  width: toRem(28);
  height: toRem(28);

  background-image: var(--b-color-fill-ocean-100-gradient);

  cursor: pointer;
}

.messageContainer {
  position: absolute;
  right: toRem(70);

  display: flex;
  gap: var(--b-space-xs);
  align-items: center;
  opacity: 0;
  transform: translateX(toRem(70));

  box-shadow: 0 toRem(2) toRem(12) 0 rgba(0, 0, 0, 0.3);
  border-top-left-radius: var(--b-border-radius-50);
  border-bottom-left-radius: var(--b-border-radius-50);
  padding: var(--b-space-s) var(--b-space-m);

  text-overflow: ellipsis;
  text-wrap: nowrap;

  background: var(--b-color-bg-white);
  visibility: hidden;
  transition:
    opacity 0.2s ease-out,
    transform 0.5s ease,
    visibility 0.3s ease;

  [dir='rtl'] & {
    right: unset;
    left: toRem(70);

    transform: translateX(toRem(-70));

    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    border-top-right-radius: var(--b-border-radius-50);
    border-bottom-right-radius: var(--b-border-radius-50);
  }
}

.textLink {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: var(--b-color-text-primary);
    font-weight: 500;
  }
}
