$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.header1 {
  @include font-xl-700;
  margin: var(--b-space-m) 0;

  @media #{$fromLaptop} {
    @include font-2xl-700;
  }
}

.header2 {
  @include font-l-700;
  margin: var(--b-space-m) 0;

  @media #{$fromLaptop} {
    @include font-xl-700;
  }
}

.header3 {
  @include font-ml-700;
  margin: var(--b-space-s) 0;

  @media #{$fromLaptop} {
    @include font-l-700;
  }
}

.header4 {
  @include font-m-700;
  margin: var(--b-space-s) 0;

  @media #{$fromLaptop} {
    @include font-ml-700;
  }
}

.header5 {
  @include font-s-700;
  margin: var(--b-space-xs) 0;

  @media #{$fromLaptop} {
    @include font-m-700;
  }
}

.header6 {
  @include font-xs-700;
  margin: var(--b-space-xs) 0;

  @media #{$fromLaptop} {
    @include font-s-700;
  }
}

.paragraph {
  @include font-m-300;
  margin: var(--b-space-xs) 0;
}

.strong {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.list {
  margin: var(--b-space-xs) 0;
  padding-left: var(--b-space-l);

  list-style-position: outside;

  & & {
    margin: 0;
  }

  &:is(ul) {
    list-style-type: disc;

    & & {
      list-style-type: circle;
    }
  }

  &:is(ol) {
    list-style-type: decimal;
  }
}

.listItem {
  @include font-m-300;
}

.blockquote {
  @include font-m-300;
  overflow: hidden;

  margin: var(--b-space-xs) 0;
  border-left: toRem(2) solid var(--b-color-fill-neutral-02);
  padding-left: var(--b-space-xs);
}

.table {
  border: toRem(1) solid var(--b-color-fill-neutral-02);

  border-collapse: collapse;
}

.tableHeader,
.tableCell {
  @include font-m-300;

  border: toRem(1) solid var(--b-color-fill-neutral-02);
  padding: var(--b-space-xs);
}

.tableHeader {
  font-weight: 700;
}
