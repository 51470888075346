$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mixins/fonts';
@import 'src/ui/styles/tools/mixins/text';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mediaQueries';

.container {
  display: flex;
  gap: var(--b-space-xxs);
  flex-direction: column;
}

.inputWrapper {
  position: relative;
}

.iconWrapper {
  position: absolute;
  top: #{toRem(8)};
  right: #{toRem(12)};

  cursor: pointer;

  [dir='rtl'] & {
    right: unset;
    left: #{toRem(12)};
  }
}

.input {
  @include textField;
  width: 100%;
}

.inputError {
  @include textField;

  box-shadow: 0 0 0 toRem(1) var(--b-color-support-error-dark) inset;
}
