$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/fonts';

.select {
  @include font-m-300;

  box-shadow: 0 0 0 #{toRem(1)} var(--b-color-field-enabled) inset;
  border: none;
  border-radius: #{toRem(8)};
  width: 100%;
  padding: #{toRem(8)} #{toRem(12)};

  outline: none;

  -webkit-appearance: unset;
  -moz-appearance: unset;

  appearance: unset;

  background-color: var(--b-color-bg-white);
  background-image: url('/assets/images/ChevronDown.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - #{toRem(17)}) center;

  [dir = 'rtl'] & {
    background-position: #{toRem(17)} center;
  }

  &:focus,
  &:active {
    box-shadow: 0 0 0 #{toRem(2)} var(--b-color-field-active) inset;
  }

  &:disabled {
    box-shadow: 0 0 0 #{toRem(1)} var(--b-color-field-disabled) inset;
  }

  &:hover:not(:focus):not(:active):not(:disabled) {
    box-shadow: 0 0 0 #{toRem(2)} var(--b-color-field-hover) inset;
  }
}

.error {
  box-shadow: 0 0 0 toRem(1) var(--b-color-support-error-dark) inset;

  &:focus {
    box-shadow: 0 0 0 toRem(1) var(--b-color-support-error-dark) inset;
  }
}
