$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';

.container {
  position: absolute;

  margin-top: var(--b-space-xl);
  box-shadow: var(--b-shadow-soft);
  border: #{toRem(1)} solid var(--b-color-fill-neutral-02);
  border-radius: #{toRem(16)};
  padding: var(--b-space-xl);

  outline: unset;

  background-color: var(--b-color-bg-white);
}

.upwards {
  bottom: 100%;

  margin-top: unset;
  margin-bottom: var(--b-space-xl);
}
