$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  padding: var(--b-space-xl) var(--b-space-l) 0 var(--b-space-l);

  @media #{$fromLaptop} {
    padding: var(--b-space-xl) var(--b-space-l) 0 var(--b-space-3xl);

    [dir='rtl'] & {
      padding: var(--b-space-xl) var(--b-space-3xl) 0 var(--b-space-l);
    }
  }
}

.exitButton {
  align-self: flex-start;

  padding-left: var(--b-space-m);

  [dir='rtl'] & {
    padding-left: unset;
    padding-right: var(--b-space-m);
  }
}
