$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';

.container {
  display: flex;
  gap: var(--b-space-m);
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  padding: var(--b-space-m);
}

.defaultText {
  margin-top: calc(var(--b-space-xl) * -1);
}

.mainText {
  max-width: toRem(524);
}

.secondaryText {
  max-width: toRem(702);
}
