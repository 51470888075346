:root {
  /** Button primary **/
  --b-color-button-primary-enabled: linear-gradient(
    90deg,
    #30a2bf 0%,
    #2f5a76 100%
  );
  --b-color-button-primary-hover: linear-gradient(
    90deg,
    #268299 0%,
    #26485e 100%
  );
  --b-color-button-primary-pressed: linear-gradient(
    90deg,
    #1d6173 0%,
    #1c3647 100%
  );
  /** Button secondary **/
  --b-color-button-secondary-enabled: #2f5a76;
  --b-color-button-secondary-hover: #eaeef1;
  --b-color-button-secondary-pressed: #1c3647;
  --b-color-button-secondary-disabled: #eaeef1;
  /** Button inverse **/
  --b-color-button-inverse-enabled: #ffffff;
  --b-color-button-inverse-hover: #acbdc8;
  --b-color-button-inverse-pressed: #ffffff;
  /** Button on image **/
  --b-color-button-on-image-enabled: #ffffff;
  --b-color-button-on-image-hover: #eaeef1;
  --b-color-button-on-image-pressed: #ffffff;
  /** Cancel button **/
  --b-color-button-cancel-enabled: #960b0d;
  --b-color-button-cancel-hover: #eaeef1;
  --b-color-button-cancel-pressed: #960b0d;

  /** Button myBarcelo primary**/
  --b-color-button-myBarcelo-primary-enabled: #384044;
  --b-color-button-myBarcelo-primary-hover: #ebecec;
  --b-color-button-myBarcelo-primary-pressed: #141719;
  --b-color-button-myBarcelo-primary-disabled: #eaeef1;
  /** Button myBarcelo secondary**/
  --b-color-button-myBarcelo-secondary-enabled: #384044;
  --b-color-button-myBarcelo-secondary-hover: #ebecec;
  --b-color-button-myBarcelo-secondary-pressed: #384044;
  --b-color-button-myBarcelo-secondary-disabled: #eaeef1;

  /** Interactive primary **/
  --b-color-interactive-primary-active: #384044;
  --b-color-interactive-primary-hover: #eaeef1;
  --b-color-interactive-primary-selected: #e0e6ea;
  --b-color-interactive-primary-enabled: #384044;
  --b-color-interactive-primary-disabled: #afb3b4;
  --b-color-interactive-primary-pressed: #acbdc8;
  /** Interactive secondary **/
  --b-color-interactive-secondary-enabled: #30a2bf;
  --b-color-interactive-secondary-hover: #26485e;
  --b-color-interactive-secondary-pressed: #1c3647;
  --b-color-interactive-secondary-disabled: #acbdc8;

  /** Support **/
  --b-color-support-success: #197625;
  --b-color-support-success-dark: #0f5317;
  --b-color-support-success-light: #e8f1e9;
  --b-color-support-warning: #ffc83d;
  --b-color-support-warning-dark: #aa5403;
  --b-color-support-warning-light: #fff9ec;
  --b-color-support-error: #e40f13;
  --b-color-support-error-dark: #960b0d;
  --b-color-support-error-light: #fce7e7;
  --b-color-support-info: #0091f0;
  --b-color-support-info-dark: #004b81;
  --b-color-support-info-light: #e5f4fd;
  --b-color-support-neutral: #f5f5f6;

  /** Backgrounds **/
  --b-color-bg-light: #f5f5f6;
  --b-color-bg-white: #ffffff;
  --b-color-bg-overlay: rgba(56, 64, 68, 0.7);
  --b-color-bg-overlay-transparent: rgba(56, 64, 68, 0);

  /** myBarcelo **/
  --b-color-myBarcelo-start: #d8ce8d;
  --b-color-myBarcelo-intense: #b36c91;
  --b-color-myBarcelo-unique: #000000;
  --b-color-text-myBarcelo-dark: #232323;
  --b-color-text-myBarcelo-light: #fafafa;

  /** Fills **/
  --b-color-fill-neutral-00: #ffffff;
  --b-color-fill-neutral-01: #f5f5f6;
  --b-color-fill-neutral-02: #d7d9da;
  --b-color-fill-neutral-03: #afb3b4;
  --b-color-fill-neutral-04: #384044;
  --b-color-fill-ocean-100: #274b62;
  --b-color-fill-ocean-15: #e0e6ea;
  --b-color-fill-ocean-20: #acbdc8;
  --b-color-fill-ocean-10: #eaeef1;
  --b-color-fill-ocean-100-gradient: linear-gradient(
    99.82deg,
    #30a2bf 0%,
    #2f5a76 100%
  );
  --b-color-fill-pool-10: #e4eff4;
  --b-color-fill-pool-100-gradient: linear-gradient(90deg, #2b92ac, #2a516a);
  --b-color-fill-pool-140: #1d6173;

  /** Icons **/
  --b-color-icon-dark: #384044;
  --b-color-icon-light: #ffffff;
  --b-color-icon-mid: #acbdc8;
  --b-color-icon-disabled: #afb3b4;
  --b-color-icon-accelerator-red: #960b0d;

  /** Divider **/
  --b-color-divider: #d7d9da;

  /** Text **/
  --b-color-text-primary: #2f5a76;
  --b-color-text-light: #ffffff;
  --b-color-text-mid: #606669;
  --b-color-text-dark: #384044;
  --b-color-text-accelerator-red: #960b0d;
  --b-color-text-disabled: #afb3b4;

  /** Link **/
  --b-color-secondary-link: #274b62;
  --b-color-secondary-link-hover: #203c4e;
  --b-color-secondary-link-pressed: #172d3b;

  /** myBarceló Link **/
  --b-color-myBarcelo-link: #384044;
  --b-color-myBarcelo-link-hover: #262c2e;
  --b-color-myBarcelo-link-pressed: #0b0d0e;

  /** Field colors **/
  --b-color-field-enabled: #384044;
  --b-color-field-hover: #384044;
  --b-color-field-active: #2f5a76;
  --b-color-field-disabled: #afb3b4;

  /** Tags & ribbons **/
  --b-color-tag-prices: #d9e8db;
  --b-color-tag-renovated: #c4d3ff;
  --b-color-tag-opening: #ffcde0;
  --b-color-tag-accelerator: #ffcb96;
  --b-color-tag-highlight: #eaeef1;

  /** Hotel alerts **/
  --b-color-button-hotel-alert-hover: #ffd5ab;
  --b-color-button-hotel-alert-pressed: #cca278;

  /** Tripadvisor ratings **/
  --b-color-tripadvisor-rating: #00a682;

  /** Stepper **/
  --b-color-stepper-enabled: #0a8754;
  --b-color-stepper-hover: #0e593c;

  /** Tooltip **/
  --b-color-tooltip-background: var(--b-color-text-mid);

  /** Ribbons **/
  --b-color-ribbon-background: #d9e8db;

  /** Scrollbars **/
  --b-color-scrollbar-track: #30a2bf;
  --b-color-scrollbar-thumb: #eaeef1;

  /** Loader **/
  --b-color-loader-gradient-from: #30a2bf;
  --b-color-loader-gradient-to: #315973;
  --b-color-loader-primary: #384044;
  --b-color-loader-inverse: #ffffff;
  --b-color-loader-secondary: #274b62;
  --b-color-loader-error: #960b0d;
}
