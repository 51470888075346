$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';.container {
  position: relative;

  display: inline-block;

  width: var(--size);
  height: var(--size);
}

.loader {
  --size-to-border-ratio: 8;
  --border-size: calc(var(--size) / var(--size-to-border-ratio));

  position: absolute;

  border: var(--border-size) solid transparent;
  border-radius: 50%;

  inset: 0;
  background-image: conic-gradient(
    from 90deg,
    var(--to-color),
    var(--from-color) 270deg,
    transparent 270deg
  );
  background-origin: border-box;
  mask:
    linear-gradient(#000 0 0) border-box,
    linear-gradient(#000 0 0) padding-box;
  mask-composite: exclude;

  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(90deg);
  }
  40% {
    transform: rotate(160deg);
  }
  60% {
    transform: rotate(230deg);
  }
  80% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
