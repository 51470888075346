$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.phoneFieldContainer {
  --react-international-phone-dropdown-top: 40px;
  --react-international-phone-dropdown-left: -12px;

  display: flex;
  gap: var(--b-space-xs);
  align-self: stretch;

  border: toRem(1) solid var(--b-color-field-enabled);
  border-radius: var(--b-border-radius-8);
  width: 100%;
  height: toRem(40);
  padding: var(--b-space-xs) var(--b-space-s);

  background: var(--b-color-bg-white);

  [dir='rtl'] & {
    --react-international-phone-dropdown-left: auto;
  }
}

.phoneFieldContainerError {
  border-color: var(--b-color-support-error-dark);
}

.countrySelector {
  display: flex;
  gap: var(--b-space-xs);
  align-items: center;
  align-self: stretch;

  height: toRem(24);
}

.countrySelectorButton {
  border: none;

  &:hover {
    background-color: inherit;
  }
}

.countrySelectorButton > div {
  gap: var(--b-space-xxs);
}

.countrySelectorDropdownArrow {
  border: none;
  min-width: toRem(16);
  min-height: toRem(16);

  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIKICAgICB2aWV3Qm94PSIwIDAgMTYgMTYiIGZpbGw9Im5vbmUiPgogIDxwYXRoIGQ9Ik0zLjY2NjY5IDZMOC4xNjY2OSAxMC41TDEyLjY2NjcgNiIgc3Ryb2tlPSIjMzg0MDQ0IgogICAgICAgIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
}

.phoneFieldContainer > .phoneInput {
  @include font-m-300;
  @include textField;
  box-shadow: none;
  width: 100%;
  height: toRem(24);

  color: var(--b-color-text-mid);

  &:hover, &:focus-visible, &:focus, &:hover:not(:focus):not(:active):not(:disabled) {
    box-shadow: none;
  }

  [dir='rtl'] & {
    direction: rtl;
  }
}

.countrySelectorDropdown {
  display: flex;
  align-items: center;
  align-self: stretch;

  border: toRem(1) solid var(--b-color-text-dark);
  border-radius: var(--b-border-radius-10);
  width: fit-content;
  padding: var(--b-space-m) var(--b-space-xs);
}

.countrySelectorDropdown:focus-visible {
  outline: none;
}

.countrySelectorDropdownItem {
  display: flex;
  gap: var(--b-space-xs);
  align-items: center;

  width: toRem(352);
  height: toRem(48);
  padding: var(--b-space-s) var(--b-space-s) var(--b-space-s) toRem(17);
}

.countrySelectorDropdownItem > img {
  width: toRem(30);
}
